import defaultTheme from 'styles/theme';

export enum Theme {
    DEFAULT = 'DEFAULT',
}

export type ThemeType = typeof defaultTheme;
export type TextColorType = keyof ThemeType['colors'];
export type FontSizes = keyof ThemeType['fontSizes'];
export type FontWeight = keyof ThemeType['fontWeight'];

export type SingleFlatType = {
    buildingFloor: string;
    flatAmenities: any;
    flatFeatured: boolean | null;
    flatArea: number;
    flatNumber: number;
    flatPrice: number;
    flatRoomsQuantity: number;
    flatStatus: string;
    investmentBuilding: string;
    priceMeter: number;
};

export type DetailsFlatType = {
    budynek: { description: string; id: string; name: string };
    cena: number;
    iloscPokoi: number;
    metraz: number;
    numerMieszkania: number;
    pietro: { description: string; id: string; name: string };
    status: string;
    udogodnienia: { description: string; id: string; name: string };
};

interface GalleryPhoto {
    photo: ImageType;
}

export type FlatType = {
    creditBoxData: {
        safeCreditAdditionalInformations: string;
        safeCreditDisclaimer: string;
        safeCreditInstallmentAmount: string;
        safeCreditTitle: string;
    };
    flat: {
        id: string;
        investmentFlat: {
            buildingFloor: { name: string };
            enableSafeCreditInfo: boolean | null;
            flatAmenities: { name: string };
            flatFeatured: boolean | null;
            featuredDescription: string;
            flatGallery: GalleryPhoto[];
            flatArea: number;
            flatImage2d: ImageType;
            flatImage3d: ImageType;
            flatImage2dAdditional: ImageType;
            flatImage3dAdditional: ImageType;
            flatNumber: number;
            flatPrice: number;
            flatRoomsQuantity: number;
            flatStatus: { name: string };
            investmentBuilding: { name: string };
            pdfBrochure: { sourceUrl: string; mediaItemUrl: string };
            safeCreditContactPhone: string | null;
            safeCreditInstallmentAmount: number | null;
        };
    };
};

export type FlatsTableType = { allWpMieszkanie: { nodes: SingleFlatType[] } };

export type LinkType = { title: string; url: string; target?: string };

export type TitleDesriptionType = { title: string; description: string };

export type ImageType = {
    altText: string;
    mediaDetails: { height: number; width: number };
    sourceUrl: string;
};

export type AdvantagesType = {
    description: string;
    icon: ImageType;
    title: string;
};

export type ImageMapType = {
    availableCount: string;
    buildingTax: string;
    coordinates: string;
    floorTax: string;
};

export type FloorFlatType = {
    coordinates: string;
    flat: {
        id: string;
        investmentFlat: {
            flatArea: number;
            flatNumber: number;
            flatPrice: number;
            flatStatus: { name: string };
            investmentBuilding: { name: string; slug: string };
        };
    };
};

export interface OptionType {
    capital: string;
    continent: string;
    currency: string;
    emoji: string;
    emojiU: string;
    label: string;
    languages: string[];
    name: string;
    native: string;
    phone: string;
    value: string;
}

export interface FormTypes {
    name: string;
    country: OptionType;
    phone?: string;
    email: string;
    message?: string;
    privacy: boolean;
}

export enum ToastTypes {
    DEFAULT = 'default',
    ERROR = 'error',
    SUCCESS = 'success',
    WARNING = 'warning',
}

export type SingleGarageType = {
    garageArea: number;
    garageNumber: number;
    garagePrice: number;
    garageStatus: string;
};

export type SingleStorageRoomType = {
    storageRoomArea: number;
    storageRoomNumber: number;
    storageRoomPrice: number;
    storageRoomStatus: string;
};

export interface GarageType {
    coordinates: string;
    garage: {
        id: string;
        investmentGarage: {
            garageArea: number;
            garageNumber: number;
            garagePrice: number;
            garageStatus: { name: string };
            investmentBuilding: { name: string; slug: string };
        };
    };
}

export interface StorageType {
    coordinates: string;
    storageRoom: {
        id: string;
        investmentStorageRoom: {
            storageRoomArea: number;
            storageRoomNumber: number;
            storageRoomPrice: number;
            storageRoomStatus: { name: string };
        };
    };
}

export interface MediaDetails {
    width: number;
    height: number;
}

export type RealizationType = {
    mapPinLat?: string;
    mapPinLng?: string;
    realizationAddress: string;
    realizationDescription: string;
    realizationGallery: {
        image: ImageType;
        type: string;
        video: any;
    }[];
    realizationImage: ImageType;
    realizationName: string;
};

export type MarkerType = {
    title: string;
    position: {
        lat: number;
        lng: number;
    };
    iconUrl: string;
    slug?: string;
    realization?: RealizationType;
};
